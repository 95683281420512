import script from "./Permissions.vue?vue&type=script&setup=true&lang=ts"
export * from "./Permissions.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QCircularProgress from 'quasar/src/components/circular-progress/QCircularProgress.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QTree from 'quasar/src/components/tree/QTree.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCircularProgress,QForm,QTree,QBtn});
