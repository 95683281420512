import { PermissionsClient, } from "../../api/nswag.generated";
export default class PermissionsService {
    permissionsClient = new PermissionsClient();
    async getRolesPermissions() {
        return await this.permissionsClient.getRolesPermissions();
    }
    async setRolesPermissions(command) {
        return await this.permissionsClient.setRolesPermissions(command);
    }
}
